import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import matchdocs from '../../assets/images/pd/match-doctors.png'

import homepage from '../../assets/images/asc/homepage.jpg'
import blog from '../../assets/images/asc/blog.jpg'
import doctorprofiles from '../../assets/images/asc/doctor-profiles.jpg'
import medicalrecords from '../../assets/images/asc/medical-records.jpg'
import painevaluation from '../../assets/images/asc/pain-evaluation.jpg'
import testimonials from '../../assets/images/asc/testimonials.jpg'
import treatmentplan from '../../assets/images/asc/treatment-plan.jpg'
import videos from '../../assets/images/asc/videos.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">Full Stack</Text>
                                <Title mb="0.5em">Atlantic Spine Center</Title>
                                <Text variant="p" mb="1.6em">
                                    I designed, developed and maintained{' '}
                                    <a
                                        href="https://www.atlanticspinecenter.com/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        atlanticspinecenter.com
                                    </a>{' '}
                                    for a decade.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    The most recent site created in 2019 was an
                                    enterprise class website built for speed,
                                    organic content growth and paid marketing
                                    traffic designed for mobile devices.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Over 1 million user visits over the past 2
                                    years. Several million more over the prior 8
                                    years and 5 previous sites.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={matchdocs}
                                    alt="Website Analytics"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Atlantic Spine Center
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    10 years
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.atlanticspinecenter.com"
                                    >
                                        atlanticspinecenter.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={matchdocs}
                                    alt="Match Docs"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    A Decade of Master Class
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    Atlantic Spine Center was a full stack web
                                    presence entrusted to me for over 10 years.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    I created and maintained all of the prior 6
                                    websites for{' '}
                                    <a
                                        href="https://spine-center-dot-atlantic-spine.uc.r.appspot.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        atlanticspinecenter.com
                                    </a>
                                    .
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Each website was built using the most up to
                                    date website frameworks that would deliver
                                    ogranic and paid content to a wide range of
                                    audiences and device mediums.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Every page was carefully constructed and
                                    optimized to deliver pages in under a second
                                    with seamless page transitions.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="gatsby" title="01. Gatsby" left>
                        To acheive seamless and timely content delivery I turned
                        to gatsby, a static site generator bundled into a Docker
                        container running nginx.
                    </Key>

                    <Key name="docker" title="02. Docker">
                        All sites over the years were developed and delivered in
                        the most up to date content delivery systems. For setup
                        and delivery, docker was perfect for the most recent
                        project.
                    </Key>

                    <Key name="react" title="03. React" left>
                        Powering gatsby is React. I started developing react
                        apps since it's inception. I'm quite comfortable
                        building entire sites using react.
                    </Key>

                    <Key name="algolia" title="04. Algolia">
                        Finding an embeded search to replace Google was
                        difficult. I turned to Algolia integration to cover all
                        the gatsby content providing complete control over the
                        search and results.
                    </Key>

                    <Key name="salesforce" title="05. Salesforce" left>
                        I started the original website in 2012 to integrate
                        directly with enterprise salesforce. I believe I was one
                        of the early adaptors of salesforce person accounts.
                        Prior to healthcloud, I built and customized several
                        dozen interfaces and processes throughout salesforce.
                    </Key>

                    <Key name="mysql" title="06. MySql">
                        Behind every form submissions can be an exhausting
                        process. I oversaw and developed dozens of mysql
                        databases for handling content and submissions for
                        millions of users.
                    </Key>

                    <Key name="twilio" title="07. Twilio" left>
                        Been a long time supporter of twilio and their
                        integrated telephony products. Our longest serving
                        client is integrated with twilio numbers for dynamic
                        phone tracking of marketing programs and SMS automation
                        through salesforce turned out being our most used
                        product integration.
                    </Key>

                    <Key name="mobile" title="08. Mobile First Responsive">
                        Our original site was designed for mobile responsive
                        over 10 years ago. One of the largest growing sectors of
                        internet technology is delivering interactive and
                        responsive sites optimized for mobile devices.
                    </Key>

                    <Key name="googlecloud" title="09. Google Cloud" left>
                        Our most recent addition to enterprise clients was
                        moving to google cloud. The interfaces are much more
                        intuative than AWS. Virtual and Secure environments are
                        easy to setup and manage.
                    </Key>

                    <Key name="aws" title="10. Amazon Web Services">
                        Until recently, the past 10 years were hosted by AWS. I
                        managed administration for several different AWS
                        accounts that include a broad spectrum of services from
                        EC2 to RDS.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={homepage}
                                        alt="Atlantic Spine Center Homepage"
                                    />
                                    <Screenshot src={blog} alt="Blog" />
                                    <Screenshot
                                        src={doctorprofiles}
                                        alt="Doctor Profiles"
                                    />
                                    <Screenshot
                                        src={medicalrecords}
                                        alt="Medical Records"
                                    />
                                    <Screenshot
                                        src={painevaluation}
                                        alt="Pain Evaluation"
                                    />
                                    <Screenshot
                                        src={testimonials}
                                        alt="Testimonials"
                                    />
                                    <Screenshot
                                        src={treatmentplan}
                                        alt="Treatment Plan"
                                    />
                                    <Screenshot
                                        src={videos}
                                        alt="Medical Animation Videos"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/atlantic-joint-center/">
                                Atlantic Joint Center
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
